<template>
    <svg width="28" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.6,7.4l-6.2,6.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0s-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1
      c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2L6.2,7L0.4,1.2C0.3,1.1,0.2,0.9,0.2,0.8
      s0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l6.2,6.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
      c0,0.1,0,0.2,0,0.2C7.7,7.3,7.6,7.4,7.6,7.4z"
        fill="currentColor"
      />
    </svg>
  </template>
  
  <script lang="ts" setup></script>
  